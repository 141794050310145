import { AMPLITUDE_INSTANCE_KEY } from '@nugsnet/web-sdk/src/constants'
import { AmplitudeUserContentData } from '.'
import { AmplitudeAnalytics } from '@nugsnet/web-sdk/src/services/analytics/amplitude'

import { AMPLITUDE_EVENTS } from 'constants/enums'

let amplitude: AmplitudeAnalytics

async function initAmplitude() {
  amplitude = new AmplitudeAnalytics()
  await amplitude.init(undefined, AMPLITUDE_INSTANCE_KEY)
}

async function getEventData(
  eventType: AMPLITUDE_EVENTS,
  data: AmplitudeUserContentData
): Promise<Record<string, unknown>> {
  switch (eventType) {
    case AMPLITUDE_EVENTS.USER_CONTENT_PLAYED: {
      const showName = `${data.performanceDate ? new Date(data.performanceDate).toDateString() : ''}, ${data.venue}, ${
        data.venue
      }, ${data.state}`
      const event = {
        'url': window.location.href,
        'origin': window.location.origin,
        'application state': data.applicationState,
        'artist id': data.artistId,
        'artist name': data.artistName,
        'business model': data.businessModel,
        city: data.city,
        'content subtype': data.contentSubtype,
        'content type': data.contentType,
        duration: data.duration?.toFixed(2),
        'is live': data.isLive,
        path: data.path,
        'performance date': data.performanceDate,
        'publish date': data.publishDate,
        quality: data.quality,
        'show id': data.showId,
        'show name': !data.showName ? showName : data.showName,
        'sorting type': data.sortingType,
        state: data.state,
        'stop reason': data.stopReason,
        'streaming type': data.streamingType,
        'track id': data.trackId,
        'track name': data.trackName,
        venue: data.venue
      }

      if (data.duration) {
        const identify = new window[AMPLITUDE_INSTANCE_KEY].Identify()
        identify.add('Audio playback duration counter', (data.duration / 60).toFixed(2))
       
        if (identify) await amplitude.identify(identify)
      }

      return event
    }
    case AMPLITUDE_EVENTS.USER_PLAYBACK_INITIATED: {
      return {
        'artist id': data.artistId,
        'artist name': data.artistName,
        'content subtype': data.contentSubtype,
        'content type': data.contentType,
        page: data.page,
        path: data.path,
        section: data.section,
        'show id': data.showId,
        'show name': data.showName
      }
    }
  }
}

export async function logAmplitudeEvent(eventType: AMPLITUDE_EVENTS, data: AmplitudeUserContentData) {
  if (!amplitude) {
    await initAmplitude()
  }
  const eventData = await getEventData(eventType, data)
  await amplitude.logEvent(eventType, eventData)
}
