/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EVENT_PLAYBACK_STATUS } from '@nugsnet/web-sdk/src/services/telemetry/enums'
import 'url-change-event'
import { BaseVideoTrackerService, VideoChapter } from './baseVideoTrackerService'

export class PlayerData {
  player: object
  chapters: Array<VideoChapter>

  constructor(player: object, chapters: Array<VideoChapter>) {
    this.player = player
    this.chapters = chapters
  }
}

export class TrackerJWPlayerService extends BaseVideoTrackerService {
  private player: any = null
  private videoEl: any = null

  constructor() {
    super()
  }

  //#region Public Methods
  public setPlayer(data: PlayerData): void {
    if (this.player != null) {
      this.unregisterListeners()
    }
    this.videoEl = null
    this.sendStorage()
    this.player = data.player
    this.initData()
    this.registerListeners()
    if (data.chapters) {
      this.initialChapters = data.chapters
    }

    this.sendEventsTimeoutId = setInterval(() => {
      this.sendEvents()
    }, 5000)
  }

  private registerListeners() {
    this.player.on('ready', this.readyListener.bind(this))
    this.player.on('play', this.playListener.bind(this))
    this.player.on('pause', this.pauseListener.bind(this))
    this.player.on('complete', this.completeListener.bind(this))
    this.player.on('error', this.errorListener.bind(this))
    this.player.on('time', this.timeListener.bind(this))

    window.addEventListener('urlchangeevent', () => {
      if (this.player == undefined) {
        return
      }

      if (this.sessionStarted) {
        this.emmitRangeEnded(EVENT_PLAYBACK_STATUS.COMPLETED)
        this.emmitChapterEnded(EVENT_PLAYBACK_STATUS.COMPLETED)
        this.emmitEnded(EVENT_PLAYBACK_STATUS.CLOSED)
      }

      clearTimeout(this.videoRangesTimeoutId)
      clearTimeout(this.chapterRangesTimeoutId)
      clearTimeout(this.progressTimeoutId)
      clearTimeout(this.sendEventsTimeoutId)

      this.sendStorage()
      this.player = undefined
    })
  }

  private unregisterListeners() {
    this.player.off('ready', this.readyListener)
    this.player.off('play', this.playListener)
    this.player.off('pause', this.pauseListener)
    this.player.off('complete', this.completeListener)
    this.player.off('error', this.errorListener)
    this.player.off('time', this.timeListener)
  }
  //#endregion

  //#region Event listeners
  protected timeListener(e: any) {
    this.onTimeListener(e.currentTime)
  }

  protected readyListener() {
    this.mapChapters()
  }
  protected errorListener(err: any) {
    console.log('JWPlayer event = error' + err)
  }
  //#endregion

  //#region Helper Methods
  protected override getHTMLMediaElement(): HTMLMediaElement {
    if (!this.videoEl) {
      this.videoEl = this.player.getContainer().querySelector('video')
    }
    return this.videoEl
  }
  //#endregion
}
