import { CONTENT_TYPE, CONTENT_SUBTYPE, QUALITY } from '../constants/enums'

export type ContentMetadata = {
  artistName?: string | null
  city?: string | null
  contentType?: CONTENT_SUBTYPE.AUDIO | CONTENT_SUBTYPE.VIDEO | null
  contentSubtype?: 
  | CONTENT_TYPE.SHOW
  | CONTENT_TYPE.ALBUM
  | CONTENT_TYPE.SINGLE
  | CONTENT_TYPE.PLAYLIST
  | CONTENT_TYPE.ALBUM
  | CONTENT_TYPE.RADIO
  | CONTENT_TYPE.LIVESTREAM
  | CONTENT_TYPE.SVOD
  | CONTENT_TYPE.EXCLUSIVE
  | CONTENT_TYPE.WEBCAST
  | null
  isLive?: boolean | null
  performanceDate?: string | null
  publishDate?: string | null
  quality?: QUALITY.LOSSLESS | QUALITY.LOSSY | QUALITY.MQA | QUALITY.SONY_360 | QUALITY.SD | QUALITY.HD | null
  showName: string //May 08, 2022, Ryman Auditorium, Nashville, TN
  state?: string | null // 'TX'
  venue?: string | null
  trackId?: string | null
  trackName?: string | null
}

export type ConfigurationSettings = {
  videoTelemetry: boolean
  amplitude: boolean
}

export class BaseTrackerService {
  protected contentMetadata: ContentMetadata
  public config: ConfigurationSettings

  constructor() {
    this.contentMetadata = {
      artistName: undefined,
      city: undefined,
      contentType: undefined,
      contentSubtype: undefined,
      isLive: false,
      performanceDate: undefined,
      publishDate: undefined,
      quality: undefined,
      showName: '', //May 08, 2022, Ryman Auditorium, Nashville, TN
      state: undefined, // 'TX'
      venue: undefined
    }

    this.config = {
      videoTelemetry: true,
      amplitude: false
    }
  }

  public addContentMetadata(contentMetadata: ContentMetadata) {
    if (!contentMetadata) {
      return this
    }

    this.contentMetadata = contentMetadata
    return this
  }

  public configurate(config: ConfigurationSettings) {
    if (!config) {
      return this
    }

    this.config = config
    return this
  }
}
