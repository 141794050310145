import { AMPLITUDE_USER_CONTENT_PLAYED, AMPLITUDE_USER_PLAYBACK_INITIATED } from './constants'

export const enum STOP_REASON {
  TRACK_SKIP = 'track skip',
  TRACK_END = 'track end'
}

export const enum CONTENT_TYPE {
  SHOW = 'show',
  ALBUM = 'album',
  SINGLE = 'single',
  PLAYLIST = 'playlist',
  RADIO = 'radio',
  LIVESTREAM = 'livestream',
  SVOD = 'svod',
  EXCLUSIVE = 'exclusive',
  WEBCAST = 'webcast'
}

export const enum CONTENT_SUBTYPE {
  AUDIO = 'audio',
  VIDEO = 'video'
}

export const enum QUALITY {
  LOSSLESS = 'lossless',
  LOSSY = 'lossy',
  MQA = 'mqa',
  SONY_360 = 'sony 360',
  SD = 'sd',
  HD = 'hd'
}

export const enum APPLICATION_STATE {
  FOREGROUND = 'foreground',
  BACKGROUND = 'background'
}

export const enum SORTING_TYPE {
  NORMAL = 'normal',
  SHUFFLED = 'shuffled'
}

export const enum STREAMING_TYPE {
  PROGRESSIVE = 'progressive',
  HLS = 'hls',
  DASH = 'dash'
}

export const enum LIVE_STATUS {
  TRUE = 'true',
  FALSE = 'false'
}

export enum AMPLITUDE_EVENTS {
  USER_CONTENT_PLAYED = AMPLITUDE_USER_CONTENT_PLAYED,
  USER_PLAYBACK_INITIATED = AMPLITUDE_USER_PLAYBACK_INITIATED
}
