/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { version } from '../../package.json'
import { Interval, Intervals } from '@nugsnet/web-sdk/src/utils/interval'
import { Logger } from '@nugsnet/web-sdk/src/utils/logger'
import { EVENT_PLAYBACK_STATUS, TelemetrySession } from '@nugsnet/web-sdk/src/services/telemetry'
import { ACCESS_TYPE, CLIENT_PLATFORM } from '@nugsnet/web-sdk/src/services/telemetry/enums'
import { ClientData, SourceData, Subscription } from '@nugsnet/web-sdk/src/services/telemetry/types'
import { VideoContent, VideoPlaybackTelemetry } from '@nugsnet/web-sdk/src/services/telemetry/video'
import { VIDEO_TYPE } from '@nugsnet/web-sdk/src/services/telemetry/video/enums'
import {
  ChapterProgressData,
  VideoChapterEndedTelemetryEvent,
  VideoRangeEndedTelemetryEvent
} from '@nugsnet/web-sdk/src/services/telemetry/video/events'
import { MissingTelemetryEventsError } from '@nugsnet/web-sdk/src/errors'
import { getCookie } from 'typescript-cookie'
import { uuid } from '@nugsnet/web-sdk/src/utils'
import { BaseTrackerService } from './baseTrackerService'
import { AMPLITUDE_EVENTS, LIVE_STATUS, STOP_REASON } from '../constants/enums'
import { AmplitudeUserContentData } from './amplitude'
import { logAmplitudeEvent } from './amplitude/amplitudeService'

export const DEBUG = new Logger()
export class VideoChapter {
  chapterSeconds = 0
  chaptername = ''
}

export type ChapterFullData = {
  start: number
  end: number
} & ChapterProgressData

export type UserData = {
  userId: string
  subscription: Subscription
}

export class BaseVideoTrackerService extends BaseTrackerService {
  public videoPlaybackTelemetry = new VideoPlaybackTelemetry()
  protected chapters = new Array<ChapterFullData>()
  protected currentChapter?: ChapterFullData
  protected currentPlayingStatus = EVENT_PLAYBACK_STATUS.PAUSED
  protected sendEventsTimeoutId: any
  protected progressTimeoutId: any
  protected videoRangesTimeoutId: any
  protected chapterRangesTimeoutId: any
  protected maxTimestamp = 0
  protected currentChapterIntervals = new Array<Interval>()
  protected prevPosition = 0
  protected initialChapters = new Array<VideoChapter>()
  protected rangeStartedPosition: number | null
  protected clientData: ClientData
  protected contentData: VideoContent
  protected session: TelemetrySession<VideoContent>
  protected authToken?: string
  protected sentRangeEnded = false
  protected sentChapterEnded = false
  protected sessionStarted = false
  protected pendingVideoChapterEndedTelemetryEvent?: VideoChapterEndedTelemetryEvent
  protected pendingVideoRangeEndedTelemetryEvent?: VideoRangeEndedTelemetryEvent
  protected isVideoStartedTelemetryEventInitiated = false

  constructor() {
    super()
    this.rangeStartedPosition = null

    this.clientData = {
      name: '',
      platform: CLIENT_PLATFORM.WEB
    }

    this.contentData = {
      videoType: VIDEO_TYPE.VOD,
      releaseId: '',
      productId: ''
    }

    this.session = new TelemetrySession<VideoContent>(
      '',
      {
        id: '',
        status: '',
        plan: {}
      },
      this.clientData,
      this.contentData,
      ACCESS_TYPE.SUBSCRIPTION
    )
    this.videoPlaybackTelemetry.add(this.session)
  }

  //#region Public methods
  public addChapters(chapters: Array<VideoChapter>) {
    if (chapters) {
      this.initialChapters = chapters
      this.mapChapters()
    }
    return this
  }

  public addAuthToken(token: string) {
    this.authToken = token
    this.videoPlaybackTelemetry.api.headers.set('Authorization', 'Bearer ' + token)
  }

  public addUser(data: UserData) {
    if (!data) {
      return
    }

    this.session.userId = data.userId.toString()
    this.session.subscription = data.subscription
    return this
  }

  public addClient(clientData: ClientData) {
    if (clientData && !clientData.version) {
      clientData.version = version
    }
    this.session.client = clientData
    return this
  }

  public addContent(contentData: VideoContent) {
    if (!contentData) {
      return this
    }

    contentData.productId = contentData.productId.toString()
    contentData.releaseId = contentData.releaseId.toString()
    this.session.content = contentData
    return this
  }

  public addAccessType(accessType: ACCESS_TYPE) {
    this.session.accessType = accessType
    return this
  }

  public addSource(source: SourceData) {
    if (!source) {
      return this
    }

    source.artistId = source.artistId ? source.artistId.toString() : null
    source.releaseId = source.releaseId ? source.releaseId.toString() : null
    source.section = source.section ?? null
    this.session.source = source
    return this
  }

  public getBlobChapters(videoChapters: Array<VideoChapter>): string {
    let result = ''
    for (let index = 0; index < videoChapters.length; index++) {
      result += index + ' \n '
      result += this.getChapterPositionFormatted(videoChapters[index]) + ' --> '
      result +=
        index < videoChapters.length - 1 ? this.getChapterPositionFormatted(videoChapters[index + 1]) : '00:00:00,000'
      result += '\n' + videoChapters[index].chaptername + '\n\n'
    }
    return window.URL.createObjectURL(new Blob([result], { type: 'text/plain' }))
  }
  //#endregion

  //#region Event listeners

  public playListener() {
    this.currentPlayingStatus = EVENT_PLAYBACK_STATUS.PLAYING
  }
  public pauseListener() {
    this.currentPlayingStatus = EVENT_PLAYBACK_STATUS.PAUSED
  }

  public completeListener() {
    this.sessionStarted = false
    this.emmitRangeEnded(EVENT_PLAYBACK_STATUS.COMPLETED)
    this.emmitChapterEnded(EVENT_PLAYBACK_STATUS.COMPLETED)
    this.emmitEnded()

    clearTimeout(this.videoRangesTimeoutId)
    clearTimeout(this.chapterRangesTimeoutId)
    clearTimeout(this.progressTimeoutId)
    this.sendStorage()
  }

  public onTimeListener(currentTime: any) {
    this.updateMaxTimestamp()
    const isSkipped =
      this.prevPosition != 0 && Math.max(this.prevPosition, currentTime) - Math.min(this.prevPosition, currentTime) > 2

    if (!this.sessionStarted) {
      this.sessionStarted = true
      this.emmitStarted()
      this.emmitRangeStarted()
      this.emmitChapterStarted()
      this.prevPosition = currentTime
      return
    }

    this.updateCurrentChapter(currentTime, isSkipped)

    if (isSkipped) {
      this.emmitRangeEnded(EVENT_PLAYBACK_STATUS.SKIPPED)
      this.emmitRangeStarted()
      if (!this.getIsCurrentChapterWasChanged(currentTime)) {
        this.addChapterInterval(currentTime)
      }
      this.updateVideoRangePlaybackEndedTimeout()
      this.updateChapterStatusTimeout()
    } else {
      this.updateChapterInterval(currentTime)
    }

    this.prevPosition = currentTime
  }
  //#endregion

  //#region Emmit Events
  protected emmitStarted(): void {
    if (this.isVideoStartedTelemetryEventInitiated) {
      return
    }
    this.logAmplitudeEvent(AMPLITUDE_EVENTS.USER_PLAYBACK_INITIATED)
    this.session.playbackId = uuid()
    this.updateProgressTimeout()
    this.updateVideoRangePlaybackEndedTimeout()
    this.updateChapterStatusTimeout()
    this.maxTimestamp = this.getCurrentPosition()
    if (this.config.videoTelemetry) {
      this.videoPlaybackTelemetry.started().then((event) => {
        this.isVideoStartedTelemetryEventInitiated = true
        this.log(event)
      })
    }
  }

  protected emmitEnded(reason = EVENT_PLAYBACK_STATUS.COMPLETED): void {
    const playbackDuration = this.getPlaybackDuration()
    if (playbackDuration == 0) {
      console.log('Playback duration is zero')
      return
    }
    this.updateMaxTimestamp()
    this.videoPlaybackTelemetry
      .ended(
        this.formatNumbers(this.getCurrentPosition()),
        this.formatNumbers(this.maxTimestamp),
        this.formatNumbers(this.getProgress(playbackDuration, this.getVideoDuration())),
        this.formatNumbers(playbackDuration),
        reason,
        this.currentChapter
      )
      .then((event) => {
        this.log(event)
        this.isVideoStartedTelemetryEventInitiated = false
      })


      this.logAmplitudeEvent(AMPLITUDE_EVENTS.USER_CONTENT_PLAYED, {
        stopReason: reason == EVENT_PLAYBACK_STATUS.COMPLETED ? STOP_REASON.TRACK_END : STOP_REASON.TRACK_SKIP
      })
  }

  private logAmplitudeEvent(eventType: AMPLITUDE_EVENTS, data?: object) {
    if (!this.config.amplitude) {
      return
    }
    const commonData = this.getCommonData()
    const mergedData = { ...commonData, ...data }
    logAmplitudeEvent(eventType, mergedData)
  }

  private getCommonData(): AmplitudeUserContentData {
    return {
      artistId: this.session.data.source?.artistId,
      artistName: this.contentMetadata.artistName,
      businessModel: this.session.accessType,
      city: this.contentMetadata.city,
      contentType: this.contentMetadata.contentType,
      contentSubtype: this.contentMetadata.contentSubtype,
      duration: this.getPlaybackDuration(),
      isLive: this.contentMetadata.isLive ? LIVE_STATUS.TRUE : LIVE_STATUS.FALSE,
      page: this.session.data.source?.page,
      section: this.session.data.source?.section,
      path: `${this.session.data.source?.page}${
        this.session.data.source?.section ? ' - ' + this.session.data.source?.section : ''
      }`,
      performanceDate: this.contentMetadata.performanceDate,
      publishDate: this.contentMetadata.publishDate,
      quality: this.contentMetadata.quality,
      showId: this.session.data.content.releaseId,
      showName: this.contentMetadata.showName, //May 08, 2022, Ryman Auditorium, Nashville, TN
      state: this.contentMetadata.state,
      trackId: this.contentMetadata.trackId,
      trackName: this.contentMetadata.trackName,
      venue: this.contentMetadata.venue
    }
  }

  private emmitChapterStarted(): void {
    const chapter = this.getChapterModel()
    if (!chapter) {
      return
    }
    this.videoPlaybackTelemetry
      .chapterStarted({
        id: chapter.id,
        marker: chapter.marker,
        title: chapter.title
      })
      .then((event) => {
        this.emmitChapterEnded(EVENT_PLAYBACK_STATUS.PENDING)
        this.log(event)
      })
  }

  protected emmitChapterEnded(reason: EVENT_PLAYBACK_STATUS): void {
    const chapter = this.getChapterModel()
    if (!chapter) {
      return
    }

    if (!this.pendingVideoChapterEndedTelemetryEvent) {
      if (this.sentChapterEnded) {
        return
      }

      this.sentChapterEnded = true
      const currentPosition = this.getCurrentPosition()
      this.videoPlaybackTelemetry
        .chapterEnded(
          this.formatNumbers(currentPosition),
          this.formatNumbers(currentPosition),
          this.formatNumbers(chapter.duration),
          this.formatNumbers(chapter.progress),
          {
            id: chapter.id,
            title: chapter.title,
            marker: chapter.marker
          },
          reason
        )
        .then((event) => {
          this.pendingVideoChapterEndedTelemetryEvent = event as VideoChapterEndedTelemetryEvent
          this.sentChapterEnded = false
          this.log(this.pendingVideoChapterEndedTelemetryEvent)
        })
      return
    }

    if (this.pendingVideoChapterEndedTelemetryEvent) {
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.id = chapter.id
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.title = chapter.title
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.marker = chapter.marker
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.currentTimestamp = this.formatNumbers(
        chapter.currentTimestamp
      )
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.maxTimestamp = this.formatNumbers(chapter.maxTimestamp)
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.duration = this.formatNumbers(chapter.duration)
      this.pendingVideoChapterEndedTelemetryEvent.data.chapter.progress = this.formatNumbers(chapter.progress)
      this.pendingVideoChapterEndedTelemetryEvent.data.reason = reason
      if (this.config.videoTelemetry) {
        this.videoPlaybackTelemetry.update(this.pendingVideoChapterEndedTelemetryEvent)
      }
      this.log(this.pendingVideoChapterEndedTelemetryEvent)

      if (reason == EVENT_PLAYBACK_STATUS.COMPLETED || reason == EVENT_PLAYBACK_STATUS.SKIPPED) {
        this.pendingVideoChapterEndedTelemetryEvent = undefined
      }
    }
  }

  private emmitRangeStarted(): void {
    const currentPosition = this.getCurrentPosition()
    this.rangeStartedPosition = currentPosition

    if (this.config.videoTelemetry) {
      this.videoPlaybackTelemetry.rangeStarted(this.formatNumbers(currentPosition)).then((event) => {
        this.emmitRangeEnded(EVENT_PLAYBACK_STATUS.PENDING)
        this.log(event)
      })
    }
  }

  protected emmitRangeEnded(reason: EVENT_PLAYBACK_STATUS): void {
    if (this.rangeStartedPosition == null) {
      return
    }

    if (!this.pendingVideoRangeEndedTelemetryEvent) {
      if (this.sentRangeEnded) {
        return
      }

      this.sentRangeEnded = true
      this.videoPlaybackTelemetry
        .rangeEnded(
          this.formatNumbers(Math.min(this.prevPosition, this.rangeStartedPosition)),
          this.formatNumbers(Math.max(this.prevPosition, this.rangeStartedPosition)),
          this.formatNumbers(this.calcDuration(this.prevPosition, this.rangeStartedPosition)),
          reason
        )
        .then((event) => {
          this.pendingVideoRangeEndedTelemetryEvent = event as VideoRangeEndedTelemetryEvent
          this.sentRangeEnded = false
          this.log(event)
        })
      return
    }

    if (this.pendingVideoRangeEndedTelemetryEvent) {
      this.pendingVideoRangeEndedTelemetryEvent.data.startTimestamp = this.formatNumbers(
        Math.min(this.prevPosition, this.rangeStartedPosition)
      )

      this.pendingVideoRangeEndedTelemetryEvent.data.endTimestamp = this.formatNumbers(
        Math.max(this.prevPosition, this.rangeStartedPosition)
      )

      this.pendingVideoRangeEndedTelemetryEvent.data.duration = this.formatNumbers(
        this.calcDuration(this.prevPosition, this.rangeStartedPosition)
      )

      this.pendingVideoRangeEndedTelemetryEvent.data.reason = reason
      this.log(this.pendingVideoRangeEndedTelemetryEvent)
      if (this.config.videoTelemetry) {
        this.videoPlaybackTelemetry.update(this.pendingVideoRangeEndedTelemetryEvent)
      }

      if (reason == EVENT_PLAYBACK_STATUS.COMPLETED || reason == EVENT_PLAYBACK_STATUS.SKIPPED) {
        this.pendingVideoRangeEndedTelemetryEvent = undefined
      }
    }
  }

  protected emmitProgressed() {
    // console.log('emmitProgressed')
    const chapter = this.getChapterModel()
    const playbackDuration = this.getPlaybackDuration()
    this.updateMaxTimestamp()
    this.videoPlaybackTelemetry
      .progressed(
        this.formatNumbers(this.getCurrentPosition()),
        this.formatNumbers(this.maxTimestamp),
        this.formatNumbers(this.getProgress(playbackDuration, this.getVideoDuration())),
        this.formatNumbers(playbackDuration),
        this.currentPlayingStatus,
        chapter == null
          ? undefined
          : {
              id: chapter.id,
              title: chapter.title,
              marker: chapter.marker
            }
      )
      .then((event) => {
        this.log(event)
      })
  }
  //#endregion

  //#region Helper Methods

  protected getChapterPositionFormatted(videoChapter: VideoChapter): string {
    const hours = parseInt(String(videoChapter.chapterSeconds / 60 / 60))
    const restChapterSeconds = videoChapter.chapterSeconds - hours * 60 * 60
    const minutes = parseInt(String(videoChapter.chapterSeconds / 60))
    const seconds = restChapterSeconds - minutes * 60
    let minutesStr = String(minutes)
    minutesStr = minutesStr.length <= 1 ? '0' + minutesStr : minutesStr
    let secondsStr = String(seconds)
    secondsStr = secondsStr.length <= 1 ? '0' + secondsStr : secondsStr

    let hoursStr = String(hours)
    hoursStr = hoursStr.length <= 1 ? '0' + hoursStr : hoursStr
    return hoursStr + ':' + minutesStr + ':' + secondsStr
  }

  protected getChapterModel(): ChapterFullData | null {
    if (!this.currentChapter) {
      return null
    }

    const mergedIntervals = Intervals.getNonOverlappingIntervals(this.currentChapterIntervals)
    const duration = Intervals.getIntervalsDuration(mergedIntervals)
    const chapter = this.currentChapter
    const chapterDuration = this.calcDuration(chapter.start, chapter.end)
    chapter.currentTimestamp = this.formatNumbers(this.prevPosition)
    chapter.duration = this.formatNumbers(duration)
    chapter.progress = this.formatNumbers(this.getProgress(duration, chapterDuration))
    this.updateChapterMaxTimestamp()
    return chapter
  }

  protected updateChapterMaxTimestamp() {
    if (!this.currentChapter) {
      return
    }

    if (this.currentChapter.maxTimestamp < this.prevPosition) {
      this.currentChapter.maxTimestamp = this.prevPosition
    }
  }
  protected updateMaxTimestamp() {
    const currentTime = this.getCurrentPosition()
    if (this.maxTimestamp < currentTime) {
      this.maxTimestamp = currentTime
    }
  }

  protected updateProgressTimeout() {
    if (this.progressTimeoutId) {
      clearTimeout(this.progressTimeoutId)
    }

    this.progressTimeoutId = setInterval(() => {
      this.emmitProgressed()
    }, 60000)
  }

  protected updateVideoRangePlaybackEndedTimeout() {
    if (this.videoRangesTimeoutId) {
      clearTimeout(this.videoRangesTimeoutId)
    }

    this.videoRangesTimeoutId = setInterval(() => {
      this.emmitRangeEnded(EVENT_PLAYBACK_STATUS.PENDING)
    }, 30000)
  }

  protected updateChapterStatusTimeout() {
    if (this.initialChapters.length == 0) {
      return
    }

    if (this.chapterRangesTimeoutId) {
      clearTimeout(this.chapterRangesTimeoutId)
    }

    this.chapterRangesTimeoutId = setInterval(() => {
      this.emmitChapterEnded(EVENT_PLAYBACK_STATUS.PENDING)
    }, 10000)
  }

  protected updateCurrentChapter(currentPosition: number, seekEvent = false): void {
    if (this.initialChapters.length == 0) {
      return
    }

    if (this.currentChapter == null) {
      const firstChapter = this.getChapter(currentPosition)
      if (!firstChapter) {
        return
      }
      this.currentChapter = firstChapter
      this.initChapterIntervals(currentPosition)
      this.emmitChapterStarted()
      return
    }

    const chapter = this.getChapter(currentPosition)
    if (!chapter) {
      return
    }
    this.updateChapterMaxTimestamp()

    //the chapter was changed
    if (this.getIsCurrentChapterWasChanged(currentPosition)) {
      this.emmitChapterEnded(seekEvent ? EVENT_PLAYBACK_STATUS.SKIPPED : EVENT_PLAYBACK_STATUS.COMPLETED)
      this.currentChapter = {
        id: chapter.id,
        marker: chapter.marker,
        title: chapter.title,
        maxTimestamp: currentPosition,
        duration: chapter.duration,
        currentTimestamp: currentPosition,
        progress: chapter.progress,
        start: chapter.start,
        end: chapter.end
      }
      this.initChapterIntervals(currentPosition)
      this.emmitChapterStarted()
    }
  }

  protected getIsCurrentChapterWasChanged(position: number) {
    if (this.chapters.length == 0 || !this.currentChapter) {
      return
    }

    const chapter = this.getChapter(position)
    if (!chapter) {
      return
    }
    return this.currentChapter.start != chapter.start && this.currentChapter.end != chapter.end
  }

  protected initChapterIntervals(startPosition: number) {
    this.currentChapterIntervals = new Array<Interval>()
    this.addChapterInterval(startPosition)
  }

  protected addChapterInterval(position: number) {
    this.currentChapterIntervals.push(new Interval(position, position))
  }

  protected updateChapterInterval(position: number) {
    if (this.chapters.length == 0 || this.currentChapterIntervals.length == 0) {
      return
    }

    const latestInterval = this.currentChapterIntervals[this.currentChapterIntervals.length - 1]
    if (latestInterval.end < position) {
      latestInterval.end = position
    }
  }

  protected getChapter(currentPosition: number): ChapterFullData | null {
    let existingChapter = null

    if (!this.chapters || this.chapters.length == 0 || currentPosition < this.chapters[0].start) {
      return null
    }

    this.chapters.forEach((chapter: ChapterFullData) => {
      if (chapter.start <= currentPosition && chapter.end > currentPosition) {
        existingChapter = chapter
      }
    })

    if (existingChapter == null) {
      existingChapter = this.chapters[this.chapters.length - 1]
    }

    return existingChapter
  }

  protected getPlaybackDuration(): number {
    let duration = 0
    const ranges = this.getVideoRanges()
    ranges.forEach((i: Interval) => {
      duration += i.end - i.start
    })
    return duration
  }

  protected getVideoRanges(): Array<Interval> {
    const result: Array<Interval> = []

    const videoEl = this.getHTMLMediaElement()
    for (let i = 0; i < videoEl.played.length; i++) {
      result.push({
        start: videoEl.played.start(i),
        end: videoEl.played.end(i)
      })
    }
    return result
  }

  protected getCurrentPosition(): number {
    return +this.getHTMLMediaElement().currentTime
  }

  protected getVideoDuration(): number {
    const val = this.getHTMLMediaElement().duration
    return Number.isFinite(val) ? val : 0
  }

  protected calcDuration(start: number, end: number) {
    const max = Math.max(start, end)
    const min = Math.min(start, end)
    return max - min
  }

  protected getProgress(duration: number, totalDuration: number): number {
    if (totalDuration === 0) {
      return 0
    }
    const result = (duration * 100) / totalDuration
    return result
  }

  protected mapChapters() {
    if (this.initialChapters.length == 0) {
      return
    }
    this.chapters = new Array<ChapterFullData>()
    this.initialChapters.forEach((chapter, index) => {
      let end = chapter.chapterSeconds
      if (index == this.initialChapters.length - 1) {
        end = this.getVideoDuration()
      } else {
        end = this.initialChapters[index + 1].chapterSeconds
      }

      this.chapters.push({
        id: null,
        marker: chapter.chapterSeconds,
        title: chapter.chaptername,
        currentTimestamp: 0,
        duration: 0,
        maxTimestamp: 0,
        progress: 0,
        start: chapter.chapterSeconds,
        end: end
      })
    })
  }

  protected log(event?: object) {
    if (!event || !this.config.videoTelemetry) {
      return
    }

    const nntelemetry = getCookie('nnTelemetryJs')
    if (process.env.NODE_ENV == 'development' || nntelemetry) {
      console.log(
        'Telemetry',
        'Events',
        JSON.stringify({
          ...event,
          data: {
            ...this.session?.data,
            ...(event || {})
          }
        })
      )
    }
  }

  public sendEvents(): void {
    if (this.config.videoTelemetry) {
      this.videoPlaybackTelemetry.send().catch((error) => {
        if (error?.name == MissingTelemetryEventsError.name) {
          return
        }
      })
    }
  }

  protected formatNumbers(val: number): number {
    return +val.toFixed(2)
  }

  protected initData() {
    this.chapters = new Array<ChapterFullData>()
    this.currentChapter = undefined
    this.currentPlayingStatus = EVENT_PLAYBACK_STATUS.PAUSED
    this.sendEventsTimeoutId = null
    this.progressTimeoutId = null
    this.videoRangesTimeoutId = null
    this.chapterRangesTimeoutId = null
    this.maxTimestamp = 0
    this.currentChapterIntervals = new Array<Interval>()
    this.prevPosition = 0
    this.initialChapters = new Array<VideoChapter>()
    this.rangeStartedPosition = null
    this.sentRangeEnded = false
    this.sentChapterEnded = false
    this.sessionStarted = false
    this.pendingVideoChapterEndedTelemetryEvent = undefined
    this.pendingVideoRangeEndedTelemetryEvent = undefined
    this.isVideoStartedTelemetryEventInitiated = false
  }

  public sendStorage(): void {
    if (this.config.videoTelemetry) {
      this.videoPlaybackTelemetry.sendStored().catch((error) => {
        if (error?.name == MissingTelemetryEventsError.name) {
          return
        }
      })
    }
  }

  protected getHTMLMediaElement(): HTMLMediaElement {
    throw new Error('getVideoRanges not implemented')
  }

  //#endregion
}
