import {
  ACCESS_TYPE,
  CLIENT_PLATFORM,
  CLIENT_SUBPLATFORM,
  SOURCE_PAGE
} from '@nugsnet/web-sdk/src/services/telemetry/enums'

import { ClientData, SourceData } from '@nugsnet/web-sdk/src/services/telemetry/types'
import { VideoContent, VideoPlaybackTelemetry } from '@nugsnet/web-sdk/src/services/telemetry/video'
import { VIDEO_TYPE } from '@nugsnet/web-sdk/src/services/telemetry/video/enums'
import { VideoChapter } from './baseVideoTrackerService'
import { PlayerData, TrackerJWPlayerService } from './trackerJWPlayerService'
import { ConfigurationSettings, ContentMetadata } from './baseTrackerService'
import { CONTENT_SUBTYPE } from 'constants/enums'

export interface ITelemetryService {
  addJWPlayerTracker(player: object, chapters: Array<VideoChapter>): TelemetryService
  addUser(userId: string, subscriptionId: string, subscriptionStatus: string, planId: string): TelemetryService
  addAuthToken(token: string): TelemetryService
  addClient(name: string, platform: CLIENT_PLATFORM, version: string | null): TelemetryService
  addContent(releaseId: string, productId: string, videoType: VIDEO_TYPE, metadata: ContentMetadata): TelemetryService
  addAccessType(accessType: ACCESS_TYPE): TelemetryService
  addSource(pageName: string, sectionName: string): TelemetryService
  trackerJWPlayerService: TrackerJWPlayerService
}

export class TelemetryService implements ITelemetryService {
  trackerJWPlayerService: TrackerJWPlayerService

  constructor() {
    this.trackerJWPlayerService = new TrackerJWPlayerService()
  }

  public addJWPlayerTracker(player: object, chapters: Array<VideoChapter>): TelemetryService {
    this.trackerJWPlayerService.setPlayer(new PlayerData(player, chapters))
    return this
  }

  public configurate(config: ConfigurationSettings): TelemetryService {
    this.trackerJWPlayerService.configurate(config)
    return this
  }

  public addUser(userId: string, subscriptionId: string, subscriptionStatus: string, planId: string): TelemetryService {
    this.trackerJWPlayerService.addUser({
      userId: userId,
      subscription: {
        id: subscriptionId,
        status: subscriptionStatus,
        plan: {
          id: planId
        }
      }
    })
    return this
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public addAuthToken(token: string) {
    //deprecated. telemetry api doesn't need any authentication
    return this
  }

  public addClient(
    name: string,
    platform: CLIENT_PLATFORM,
    version: string,
    subplatform?: CLIENT_SUBPLATFORM
  ): TelemetryService {
    const clientData: ClientData = {
      name: name,
      platform: platform,
      version: version,
      subplatform: subplatform
    }
    this.trackerJWPlayerService.addClient(clientData)
    return this
  }

  public addContent(
    releaseId: string,
    productId: string,
    videoType: VIDEO_TYPE,
    metadata: ContentMetadata
  ): TelemetryService {
    const videoContent: VideoContent = {
      videoType: videoType,
      productId: productId,
      releaseId: releaseId
    }
   
    if (metadata && metadata.contentType && metadata.contentType == CONTENT_SUBTYPE.AUDIO ) {
      this.trackerJWPlayerService.config.videoTelemetry = false
    }
    this.trackerJWPlayerService.addContent(videoContent).addContentMetadata(metadata)

    return this
  }

  public addAccessType(accessType: ACCESS_TYPE): TelemetryService {
    this.trackerJWPlayerService.addAccessType(accessType.toLocaleLowerCase() as ACCESS_TYPE)
    return this
  }

  public addSource(
    pageName: SOURCE_PAGE,
    sectionName?: string,
    artistId?: string,
    releaseId?: string
  ): TelemetryService {
    const sourceData: SourceData = {
      page: pageName,
      section: sectionName,
      artistId: artistId,
      releaseId: releaseId
    }
    this.trackerJWPlayerService.addSource(sourceData)
    return this
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const videoPlaybackTelemetry = new VideoPlaybackTelemetry()
  videoPlaybackTelemetry.sendStored().catch(error => {
    // eslint-disable-next-line no-console
    console.log(error)
  })
})
