export const ENV = "PRODUCTION";
export const TELEMETRY_API_BASE = "https://telemetry.nugs.net";
export const AMPLITUDE = 1;
export const AMPLITUDE_API_KEY = "504fd2931526c709ffbabe5ec765d52a";
export const LOG = 0;
export default {
  ENV,
  TELEMETRY_API_BASE,
  AMPLITUDE,
  AMPLITUDE_API_KEY,
  LOG
};